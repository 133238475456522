import Vue from "vue";
import VueRouter from "vue-router";
// routes
import accounting from "@/router/accounting";
import communication from "@/router/communication";
import importer from "@/router/importer";
import insurance_ancillaries from "@/router/insurance-ancillaries";
import insurance_payments from "@/router/insurance-payments";
import lisacomunica from "@/router/lisacomunica";
import log from "@/router/log";
import priv_easy from "@/router/priv-easy";
import profile from "@/router/profile";
import report from "@/router/report";
import sepa from "@/router/sepa";
import wallet from "@/router/wallet";
// vuex
import store from "@/store";
// import { canModule, canResource } from "@/utils/acl";

Vue.use(VueRouter);

const routes = [
  {
    // *** INTERNAL routes ***
    path: "/",
    name: "Root",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "module.HOME",
    meta: {
      auth: true,
      module: "HOME",
      submodule: "",
    },
    //   component: Home,
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      hasAccess: true, // no permission check
      module: "LOGIN",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      hasAccess: true, // no permission check
      module: "LOGIN",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "password" */ "@/views/ForgotPassword.vue"),
  },
  // {
  //   path: "/reset-password",
  //   name: "ResetPassword",
  //   meta: {
  //     hasAccess: true, // no permission check
  //     module: "LOGIN",
  //     submodule: "",
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "password" */ "@/views/ResetPassword.vue"),
  // },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    meta: {
      hasAccess: true, // no permission check
      module: "LOGIN",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "password" */ "@/views/ResetPassword.vue"),
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "not-authorized" */ "@/components/error-pages/NotAuthorized.vue"
      ),
    meta: {
      auth: false, // authentication not require to show this page
      hasAccess: true, // no permission check
    },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "@/components/error-pages/Maintenance.vue"
      ),
    meta: {
      auth: false, // authentication not require to show this page
      hasAccess: true, // no permission check
    },
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      auth: false, // authentication not require to show this page
      hasAccess: true, // no permission check
    },
    component: () =>
      import(
        /* webpackChunkName: "not-found" */
        "@/components/error-pages/NotFound"
      ),
  },
  // DEBUG routes
  {
    path: "/about",
    name: "About",
    meta: {
      auth: true,
      hasAccess: true, // no permission check (a test page is unknown at backend-side)
    },

    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  // {
  //   path: "/bank-statement",
  //   name: "BankStatement",
  //   meta: {
  //     auth: true,
  //     hasAccess: true, // no permission check (a test page is unknown at backend-side)
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "bank-statement" */ "@/views/accounting/bank-statement/index.vue"
  //     ),
  // },
  // *** API routes ***
  ...accounting,
  ...communication,
  ...importer,
  ...insurance_payments,
  ...insurance_ancillaries,
  ...lisacomunica,
  ...log,
  ...priv_easy,
  ...profile,
  ...report,
  ...sepa,
  ...wallet,
];

const hasAccess = (name) => {
  const permissions = store.getters["auth/permissions"];
  const canModule = (code) => {
    // module.HOME
    // code: tabella backoffice.modules
    // return permissions.includes(`module.${code}`);
    // console.debug(`canModule ${code}: ${permissions.includes(code)}`);
    return permissions.includes(code);
  };
  const canResource = (resource, verb = "index") => {
    switch (true) {
      case verb.startsWith("pivot"):
      case verb.startsWith("edit"):
      case verb.startsWith("transfer"):
        verb = "edit";
        break;
      case verb.startsWith("index"):
      case verb.startsWith("summary"):
        verb = "index";
        break;
      case verb.startsWith("create"):
      case verb.startsWith("replace"):
        verb = "create";
        break;
      default:
        break;
    }
    /* if (verb.startsWith("pivot")) {
      verb = "edit";
    }
    if (verb.startsWith("index")) {
      verb = "index";
    }
    if (verb.startsWith("create")) {
      verb = "create";
    }
    if (verb.startsWith("edit")) {
      verb = "edit";
    }
    if (verb.startsWith("replace")) {
      verb = "create";
    } */

    // console.debug(
    //   `canResource ${resource}, ${verb}: ${permissions.includes(
    //     `resources.${resource}.${verb}`
    //   )}`
    // );
    return permissions.includes(`resources.${resource}.${verb}`);
  };
  // console.debug("hasAccess:", name);

  // if name containd a "dot", is a resource, otherwise is a module...
  let spl = name.split(".");
  if (spl.length > 1) {
    if (spl[0] === "module") {
      // return canModule(spl.slice(1).join("."));
      return canModule(name);
    } else {
      return canResource(...spl);
    }
  } else {
    console.error("hasAccess invalid name: ", name);
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  //   base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // check if the user is logged in
  //   const loggedIn = localStorage.getItem('user');
  const loggedIn = store.getters["auth/isLogged"];

  // console.debug(`router loggedIn: ${loggedIn}`);

  setTimeout(() => document.getElementById("app").scrollTo(0, 0), 300);

  // browser back
  if (to.name === "Login" && loggedIn) {
    console.info("STILL LOGGED IN: CANNOT GO BACK");
    next("/home");
    return;
  }

  // (1) NOTE: this block prevent to fill a list of "always accessible routes" in the switch above.
  // if a route hasAccess, no need to check hasAccess():
  // will always be accessible, if auth is required and user is loggedIn or auth is not required
  if (to.meta.hasAccess && ((to.meta.auth && loggedIn) || !to.meta.auth)) {
    next();
    return;
  }

  // if the user is not authenticated, redirect back to the login page
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }
  // acl authorization on routes
  if (!hasAccess(to.name)) {
    router.push({
      name: "not-authorized",
    });
  }

  console.info(`router index from: ${from.meta.module} to: ${to.meta.module}`);
  // NOTE: changin module -> clear store state to prevent autofetch on other modules
  // let f, t;
  // f = from.name.split(".")[0];
  // t = to.name.split(".")[0];
  // if (
  //   (f.startsWith("module") &&
  //     t.startsWith("module") &&
  //     from.name !== to.name) ||
  //   f !== t
  // ) {
  //   console.info("changing module: dispatch filters/removeAll ");
  //   store.dispatch("filters/removeAll");
  // }
  if (from.meta.module !== to.meta.module && !to.meta.keepFilter) {
    console.info("changing module: dispatch filters/removeAll");
    store.dispatch("filters/removeAll");
    store.dispatch("breadcrumbs/remove");
  } else {
    // es: da portafoglio->gruppi->edit, e nella registry-table->details->gruppi->edit
    // sono nella rotta group-edit e cerco di andare nella rotta group-edit!!!
    if (from.name === to.name) {
      console.info("route already loaded");
      // Questo avviso un po' troppo invasivo perché viene mostrato anche quando si clicca sulla breacrumb
      // Vue.prototype.$showSnackbar({
      //   preset: "info",
      //   text: `Ti trovi già nella rotta richiesta!`,
      // });
    }
  }

  next();
});

export default router;
