export default [
  {
    path: "/bank-accounts",
    name: "bank_accounts.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/bank-accounts/index.vue"
      ),
  },
  {
    path: "/bank-accounts-create",
    name: "bank_accounts.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/bank-accounts/create.vue"
      ),
  },
  {
    path: "/bank-accounts/:id/edit",
    name: "bank_accounts.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/bank-accounts/edit.vue"
      ),
  },
  {
    path: "/emessage",
    name: "emessage.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
      hasAccess: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "emessage" */ "@/views/profile/emessage/Management.vue"
      ),
  },
  {
    path: "/emessage-consumptions",
    name: "emessage.consumption",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
      hasAccess: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "emessage-consumptions" */ "@/views/profile/emessage/consumptions/index.vue"
      ),
  },
  {
    path: "/emessage-credentials",
    name: "emessage.credentials",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
      hasAccess: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "emessage-credentials" */ "@/views/profile/emessage/credentials/index.vue"
      ),
  },
  {
    path: "/emessage-panel",
    name: "emessage.panel",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
      hasAccess: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "emessage-panel" */ "@/views/profile/emessage/panel/index.vue"
      ),
  },
  {
    path: "/profile-companies-products",
    name: "module.PROF",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/companies-products/Management.vue"
      ),
  },
  {
    path: "/profile-companies-products-companies",
    name: "insurers.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurers" */ "@/views/profile/companies-products/insurers/index.vue"
      ),
  },
  {
    path: "/profile-companies-products-companies/:id/edit",
    name: "insurers.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurers" */ "@/views/profile/companies-products/insurers/edit.vue"
      ),
  },
  {
    path: "/profile-companies-products-companies-create",
    name: "insurers.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurers" */ "@/views/profile/companies-products/insurers/create.vue"
      ),
  },
  {
    path: "/profile-companies-products-insurance-risks",
    name: "insurance_risks.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-risks" */ "@/views/profile/companies-products/insurance-risks/index.vue"
      ),
  },
  {
    path: "/profile-companies-products-insurance-risks-create",
    name: "insurance_risks.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-risks" */ "@/views/profile/companies-products/insurance-risks/create.vue"
      ),
  },
  {
    path: "/profile-companies-products-insurance-risks/:id/edit",
    name: "insurance_risks.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-risks" */ "@/views/profile/companies-products/insurance-risks/edit.vue"
      ),
  },
  {
    path: "/profile-companies-products-breakdowns",
    name: "breakdowns.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "breakdowns" */ "@/views/profile/companies-products/breakdowns/index.vue"
      ),
  },
  {
    path: "/profile-companies-products-breakdowns-create",
    name: "breakdowns.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "breakdowns" */ "@/views/profile/companies-products/breakdowns/create.vue"
      ),
  },
  {
    path: "/profile-companies-products-breakdowns/:id/edit",
    name: "breakdowns.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "breakdowns" */ "@/views/profile/companies-products/breakdowns/edit.vue"
      ),
  },
  {
    path: "/profile-companies-products-mandate-codes",
    name: "mandate_codes.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "mandate_codes" */ "@/views/profile/companies-products/mandate-codes/index.vue"
      ),
  },
  {
    path: "/profile-companies-products-mandate-codes-create",
    name: "mandate_codes.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "mandate_codes" */ "@/views/profile/companies-products/mandate-codes/create.vue"
      ),
  },
  {
    path: "/profile-companies-products-mandate-codes/:id/edit",
    name: "mandate_codes.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "mandate_codes" */ "@/views/profile/companies-products/mandate-codes/edit.vue"
      ),
  },
  {
    path: "/profile-treasuries",
    name: "module.PROF.CAGG",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "CAGG",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/treasuries/Management.vue"
      ),
  },
  {
    path: "/profile-agency-treasuries",
    name: "treasuries.index0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries/agency/index.vue"
      ),
  },
  {
    path: "/profile-agency-treasuries-create",
    name: "treasuries.create0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries/agency/create.vue"
      ),
  },
  {
    path: "/profile-agency-treasuries/:id/edit",
    name: "treasuries.edit0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries/agency/edit.vue"
      ),
  },
  {
    path: "/profile-company-treasuries",
    name: "treasuries.index1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-treasuries" */ "@/views/profile/treasuries/company/index.vue"
      ),
  },
  {
    path: "/profile-company-treasuries-create",
    name: "treasuries.create1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-treasuries" */ "@/views/profile/treasuries/company/create.vue"
      ),
  },
  {
    path: "/profile-company-treasuries/:id/edit",
    name: "treasuries.edit1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-treasuries" */ "@/views/profile/treasuries/company/edit.vue"
      ),
  },
  {
    path: "/profile-various-accounting",
    name: "various_accountings.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "various-accounting" */ "@/views/profile/various-accountings/index.vue"
      ),
  },
  {
    path: "/profile-various-accounting-create",
    name: "various_accountings.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "various-accounting" */ "@/views/profile/various-accountings/create.vue"
      ),
  },
  {
    path: "/profile-various-accounting/:id/edit",
    name: "various_accountings.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "various-accounting" */ "@/views/profile/various-accountings/edit.vue"
      ),
  },

  {
    path: "/profile-salesmen",
    name: "brokers.index0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/profile/brokers/salesmen/index.vue"
      ),
  },
  {
    path: "/profile-salesmen/create",
    name: "brokers.create0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/profile/brokers/salesmen/create.vue"
      ),
  },
  {
    path: "/profile-salesmen/:id/edit",
    name: "brokers.edit0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/profile/brokers/salesmen/edit.vue"
      ),
  },
  {
    path: "/profile-salesmen-pivot/:id/pivot",
    name: "brokers.pivot",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "salesmen" */ "@/views/profile/brokers/salesmen/commissions.vue"
      ),
  },
  {
    path: "/profile-agency-brokers",
    name: "brokers.index2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-brokers" */ "@/views/profile/brokers/agency-brokers/index.vue"
      ),
  },
  {
    path: "/profile-agency-brokers/create",
    name: "brokers.create2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-brokers" */ "@/views/profile/brokers/agency-brokers/create.vue"
      ),
  },
  {
    path: "/profile-agency-brokers/:id/edit",
    name: "brokers.edit2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-brokers" */ "@/views/profile/brokers/agency-brokers/edit.vue"
      ),
  },
  {
    path: "/profile-agency-brokers-pivot/:id/pivot",
    name: "brokers.pivot2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-brokers" */ "@/views/profile/brokers/agency-brokers/commissions.vue"
      ),
  },
  {
    path: "/profile-sectors",
    name: "module.PROF.COMP",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "COMP",
    },
    component: () =>
      import(
        /* webpackChunkName: "profile-sectors" */ "@/views/profile/sectors/Management.vue"
      ),
  },
  {
    path: "/profile-agency-sectors",
    name: "sectors.index0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-sectors" */ "@/views/profile/sectors/agency/index.vue"
      ),
  },
  {
    path: "/profile-agency-sectors/create",
    name: "sectors.create0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-sectors" */ "@/views/profile/sectors/agency/create.vue"
      ),
  },
  {
    path: "/profile-agency-sectors/:id/edit",
    name: "sectors.edit0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-sectors" */ "@/views/profile/sectors/agency/edit.vue"
      ),
  },
  {
    path: "/profile-company-sectors",
    name: "sectors.index1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-sectors" */ "@/views/profile/sectors/company/index.vue"
      ),
  },
  {
    path: "/profile-company-sectors-create",
    name: "sectors.create1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-sectors" */ "@/views/profile/sectors/company/create.vue"
      ),
  },
  {
    path: "/profile-company-sectors/:id/edit",
    name: "sectors.edit1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "company-sectors" */ "@/views/profile/sectors/company/edit.vue"
      ),
  },
  {
    path: "/profile-brokers",
    name: "module.PROF.PRCO",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "brokers" */ "@/views/profile/brokers/Management.vue"
      ),
  },
  {
    path: "/profile-cooperators/:id/edit",
    name: "brokers.edit1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cooperators" */ "@/views/profile/cooperators/edit.vue"
      ),
  },
  {
    path: "/profile-cooperators",
    name: "brokers.index1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "cooperators" */ "@/views/profile/cooperators/index.vue"
      ),
  },
  {
    path: "/profile-cooperators/create",
    name: "brokers.create1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "sectors" */ "@/views/profile/cooperators/create.vue"
      ),
  },
  {
    path: "/profile-import-maps",
    name: "module.PROF.CONF",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "CONF",
    },
    component: () =>
      import(
        /* webpackChunkName: "import-maps" */ "@/views/profile/import-maps/Management.vue"
      ),
  },
  {
    path: "/profile-import-maps-broker",
    name: "import_maps.index0",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "import-maps" */ "@/views/profile/import-maps/broker/index.vue"
      ),
  },
  {
    path: "/profile-import-maps-treasury",
    name: "import_maps.index1",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "import-maps" */ "@/views/profile/import-maps/treasury/index.vue"
      ),
  },
  {
    path: "/profile-import-maps-transfer",
    name: "import_maps.transfer",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "import-maps" */ "@/views/profile/import-maps/broker/transfer.vue"
      ),
  },
  {
    path: "/users",
    name: "users.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/users/index.vue"
      ),
  },
  {
    path: "/users-create",
    name: "users.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/users/create.vue"
      ),
  },
  {
    path: "/users/:id/edit",
    name: "users.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/users/edit.vue"
      ),
  },
  {
    path: "/users-brokers-permissions",
    name: "brokers.edit3",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/brokers/index.vue"
      ),
  },
  {
    path: "/users-lisa-for-apps-permissions",
    name: "lisa_for_apps.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/lisa-for-apps/index.vue"
      ),
  },
  {
    path: "/users-reports-permissions",
    name: "reports.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-users" */ "@/views/profile/permissions/reports/index.vue"
      ),
  },
  {
    path: "/book-tags",
    name: "book_tags.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "book-tags" */ "@/views/profile/book-tags/index.vue"
      ),
  },
  {
    path: "/book-tags-create",
    name: "book_tags.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "book-tags" */ "@/views/profile/book-tags/create.vue"
      ),
  },
  {
    path: "/book-tags/:id/edit",
    name: "book_tags.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "book-tags" */ "@/views/profile/book-tags/edit.vue"
      ),
  },
  {
    path: "/users-permissions",
    name: "users.edit2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/profile/permissions/users/permissions.vue"
      ),
  },
  {
    path: "/custom-attributes-create",
    name: "attributes.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-attributes" */ "@/views/profile/custom-attributes/index.vue"
      ),
  },
  {
    path: "/custom-attributes",
    name: "attributes.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-attributes" */ "@/views/profile/custom-attributes/create.vue"
      ),
  },
  {
    path: "/custom-attributes/:id/edit",
    name: "attributes.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-attributes" */ "@/views/profile/custom-attributes/edit.vue"
      ),
  },
  {
    path: "/agency-setups",
    name: "agency_setups.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-setups" */ "@/views/profile/agency-setups/index.vue"
      ),
  },
  {
    path: "/agency-setups/:id/edit",
    name: "agency_setups.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-setups" */ "@/views/profile/agency-setups/edit.vue"
      ),
  },
  {
    path: "/profile-documents",
    name: "module.CLOU",
    meta: {
      auth: true,
      module: "CLOU",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/views/documents/Management.vue"
      ),
  },
  {
    path: "/settings",
    name: "agency_setups.edit2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
  },
  {
    path: "/insurer_participants",
    name: "insurer_participants.index",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurer-participants" */ "@/views/profile/insurer-participants/index.vue"
      ),
  },
  {
    path: "/insurer_participants",
    name: "insurer_participants.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurer-participants" */ "@/views/profile/insurer-participants/create.vue"
      ),
  },
  {
    path: "/insurer_participants",
    name: "insurer_participants.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurer-participants" */ "@/views/profile/insurer-participants/edit.vue"
      ),
  },
  {
    path: "/treasury-cash-reference",
    name: "treasury-cash-reference",
    meta: {
      auth: true,
      hasAccess: true,
      module: "PROF",
    },
    component: () =>
      import(
        /* webpackChunkName: "companies-products" */ "@/views/profile/treasuries-cash-references/Management.vue"
      ),
  },
  {
    path: "/cash-treasuries-create",
    name: "treasuries.create2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries-cash-references/treasury/create.vue"
      ),
  },
  {
    path: "/cash-treasuries/:id/edit",
    name: "treasuries.edit2",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries-cash-references/treasury/edit.vue"
      ),
  },
  {
    path: "/cash-references-create",
    name: "cash_references.create",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries-cash-references/cash-reference/create.vue"
      ),
  },
  {
    path: "/cash-references/:id/edit",
    name: "cash_references.edit",
    meta: {
      auth: true,
      module: "PROF",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-treasuries" */ "@/views/profile/treasuries-cash-references/cash-reference/edit.vue"
      ),
  },
];
